canvas{
  max-height: 402px;
}

.card-footer{
  border-radius: 0 0px 20px 20px !important;
}

.wrapper{
height: 100%;
}

.custom-tooltip {
  border-radius: 5.322px;
  background: #FFF;
  box-shadow: 0px 4px 13.31px 0px rgba(0, 0, 0, 0.15);
  padding: 0px;
  position: absolute;
  opacity: 0;
  transform: translate(-155px,15px);
  transition: opacity 0.1s ease;
}

.tooltip-content {
  color: black;
}

.custom-tooltip a{
  display: block;
  border-radius: 0px 0px 5.32px 5.32px;
  background: var(--2-secondary-s-blue, #D9E8EE);
  padding: 10px;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
}

.tooltip-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.moduleSidebar {
position: relative;
z-index: 999;
left: 0;
top: 0px;
height: 100%;
scrollbar-width: thin;
padding-top: 60px;
box-shadow: 8px 8px 18.67px 0px rgba(0, 0, 0, 0.10);
}

.module-list ul li svg {
position: absolute;
right: 25px;
top: 8px;
}

.module-list ul li.active svg {
right: 18px;
top: 0px;
}

.sidebar-btn {
position: fixed;
left: 0px;
top: 86px;
text-align: left;
padding-left: 16px;
z-index: 99999;
background-color: #fff;
outline: 0;
border: none;
width: 270px;
}

.sidebar-btn.active {
width: 50px;
margin-left: 0rem;
padding-left: 0px;
position: fixed;
top: 86px;
left: 0;
z-index: 9999;
background-color: transparent;
}
/*
.sidebar-btn.active::after {
position: absolute;
content: " ";
height: 20px;
width: 1px;
background-color: #000;
margin-right: 1rem;
right: -25px;
top: 6px;
}*/

.sidebar-btn.active::after {
   display: none;
}


.shaka-controls-container{
display: none;
}

.shaka-client-side-ad-container.shaka-hidden {
  display: none;
}

.module-list{
position: fixed;
left: 0;
right: 0;
top: 90px;
bottom: auto;
width: inherit;
overflow-y: scroll;
background-color: #FFF;
box-shadow: 8px 8px 18.67px 0px rgba(0, 0, 0, 0.10);
scroll-behavior: smooth;
}

.module-list ul{
list-style-type: none;
margin-left: 0px;
margin-top: 50px;
} 

ul.submodule li a {
color: #000;
display: flex;
align-items: center;
}

ul.submodule li.active a {
color: #000;
}

ul.nested-submodule li:first-child {
position: relative;
padding-top: 1rem;
}

ul.nested-submodule li:first-child:before {
width: 176px;
background-color: #ddd;
height: 1px;
position: absolute;
content: "";
transform: translate(-70px, -6px);
top: 0;
}

/*
ul.mcq-options img {
border-radius: 10.386px;
border: 1.231px solid #C8C8C8;
padding: 30px;
width: 100%;
margin: 1rem 0;
}

figure.image {
width: auto;
height: auto;
margin: 1rem 0;
}
*/

figure.image img {
width: auto;
height: auto;
max-width: 100%;
}

.quiz img, .reading img {max-width: 100%; height: auto;}

figure .image.image-style-side img{
width: auto;
height: auto;
max-width: 100%;
}

figure {
  margin: 0 0 0rem;
  line-height: 0;
}

ul.mcq-options span.position-relative:after {
position: absolute;
content: " ";
right: 18px;
bottom: 33px;
width: 40px;
height: 40px;
background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9ImZ1bGxzY3JlZW4iPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNMTIuNDc0NiAxMi41NjI1SDI0Ljc4MDhWMTcuNDg1SDE3LjM5NzFWMjQuODY4N0gxMi40NzQ2VjEyLjU2MjVaTTM0LjYyNTcgMTIuNTYyNUg0Ni45MzE5VjI0Ljg2ODdINDIuMDA5NFYxNy40ODVIMzQuNjI1N1YxMi41NjI1Wk00Mi4wMDk0IDM0LjcxMzZINDYuOTMxOVY0Ny4wMTk4SDM0LjYyNTdWNDIuMDk3M0g0Mi4wMDk0VjM0LjcxMzZaTTI0Ljc4MDggNDIuMDk3M1Y0Ny4wMTk4SDEyLjQ3NDZWMzQuNzEzNkgxNy4zOTcxVjQyLjA5NzNIMjQuNzgwOFoiIGZpbGw9IiM3Njc2NzYiLz4KPC9nPgo8L3N2Zz4K);
background-repeat: no-repeat;
background-size: 40px;
cursor: pointer;
}

.mcq-questions img {
width: 100%;
border-radius: 10.386px;
border: 1.231px solid #C8C8C8;
padding: 30px;
}

ul.mcq-options.images li {
width: 50%;
margin-bottom: 0.5rem;
}

ul.mcq-options.images li label {
display: flex;
justify-content: space-between;
align-items: flex-start;
flex-direction: column;
}

ul.mcq-options li label {
display: flex;
}

ul.mcq-options li label p {
margin-bottom: 0;
}

.module-list ul li ul{
margin-top: 30px;
}

.module-list ul li, .module-list h1{position: relative; margin-bottom: 0.5rem; padding-right: 1rem;}

ul.submodule.collapse.collapsed {
display: block;
}

.module-list ul li::after{
position: absolute;
content: " ";
width: 90%;
height: 1px;
background: #ddd;
top: auto;
bottom: -18px;
left: 0;
}

.module-list ul li ul li::after{
 display: none;
} 

.module-list ul li a.selected{
color: #146B90;
font-weight: 600;
}

.module-list ul li a.selected span {
color: #146B90;
font-weight: 400;
}

.module-list ul li a.selected .sidetab-img span img {
filter: invert(25%) sepia(46%) saturate(4471%) hue-rotate(177deg) brightness(79%) contrast(62%);
}

.module-list ul li.active > a {
position: relative;
color: #146B90 !important;
}

.module-list ul li a::after {
position: absolute;
content: " ";
background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNicgZmlsbD0nIzIxMjUyOSc+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNMS42NDYgNC42NDZhLjUuNSAwIDAgMSAuNzA4IDBMOCAxMC4yOTNsNS42NDYtNS42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOGwtNiA2YS41LjUgMCAwIDEtLjcwOCAwbC02LTZhLjUuNSAwIDAgMSAwLS43MDh6Jy8+PC9zdmc+);
right: 40px;
width: 15px;
height: 15px;
top: 8px;
display: none;
}

.module-list ul li a.active::after {
transform: rotate(180deg);
}

ul.nested-submodule li a:after {
display: none;
}

ul.mcq-options li {
width: 100%;
margin-top: 0.5rem;
}

ul.mcq-options {
width: 100%;
display: flex;
flex-wrap: wrap;
list-style-type: none;
padding-left: 0;
}

input[type="radio"], input[type="checkbox"] {
position: relative;
margin-right: 0.5rem;
}

input[type="radio"] {
height: 20px;
}

/*

input[type="radio"]:after {
content: "";
width: 1rem;
height: 1rem;
background-color: #D9D9D9;
border: 1px solid #949494;
border-radius: 50%;
position: absolute;
top: 3px;
left: -1px;
display: flex;
justify-content: center;
align-items: center;
opacity: 1;
}

input[type="radio"]:checked::after {
content: " ";
font-size: 10px;
font-family: 'Font Awesome 6 Pro';
background-color: #146B90;
}
*/

/* label container */
.radio-button-container {
  color: rgba(0,0,0,0.75);
  display: block;
  position: relative;
  padding-left: 28px;
  line-height: 22px;
  margin-bottom: 10px;
  margin-left: 0px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgba(0,0,0,0);
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0.25);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radio-button-container:hover input ~ .checkmark {
  border-color: rgba(0,0,0,0.5);
}

.radio-button-container input:checked ~ .checkmark {
  background-color: rgba(0,0,0,0);
  border-color: #146B90;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-button-container input:checked ~ .checkmark:after {
  display: block;
}

.radio-button-container .checkmark:after {
  top: 50%;
  left: 50%;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #146B90;
  transform: translate(-50%, -50%);
}

input[type="checkbox"] {
display: none;
}

.checkbox-button-container {
  color: rgba(0,0,0,0.75);
  display: block;
  position: relative;
  padding-left: 28px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-button-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-button-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: rgba(0,0,0,0);
  border-radius: 2px;
  border: 2px solid rgba(0,0,0,0.25);
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-button-container:hover input ~ .checkmark {
  border-color: rgba(0,0,0,0.5);
}

.checkbox-button-container input:checked ~ .checkmark {
  background-color: rgba(0,0,0,0);
  border-color: #146B90;
}

.checkbox-button-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-button-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-button-container .checkmark:after {
  top: 50%;
  left: 50%;
	width: 17px;
	height: 17px;
	border-radius: 2px;
	background: #146B90;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjI4MzcgNi4zNTE5MkwxNC40NjA1IDYuMTc1MTVMMTQuMjgzNyA1Ljk5ODM3TDEzLjQ0MjYgNS4xNTcyMUwxMy4yNjU5IDQuOTgwNTFMMTMuMDg5MSA1LjE1NzEyTDYuOTQ3OTkgMTEuMjkyNEw0LjY4NDcyIDkuMDM0NjlMNC41MDc5NCA4Ljg1ODM1TDQuMzMxMzggOS4wMzQ5MUwzLjQ5MDIyIDkuODc2MDdMMy4zMTM0NCAxMC4wNTI5TDMuNDkwMjIgMTAuMjI5Nkw2Ljc3MTM1IDEzLjUxMDhMNi45NDgxMyAxMy42ODc1TDcuMTI0OSAxMy41MTA4TDE0LjI4MzcgNi4zNTE5MloiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  transform: translate(-50%, -50%);
}

/*
input[type="checkbox"] + div {
display: flex;
align-items: center;
}

input[type="checkbox"] + div::before {
content: '';
width: 14px;
height: 14px;
border: 1px solid #949494;
cursor: pointer;
margin-right: 8px;
background-color: #D9D9D9;
}

input[type="checkbox"]:checked + div::before {
background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjI4MzcgNi4zNTE5MkwxNC40NjA1IDYuMTc1MTVMMTQuMjgzNyA1Ljk5ODM3TDEzLjQ0MjYgNS4xNTcyMUwxMy4yNjU5IDQuOTgwNTFMMTMuMDg5MSA1LjE1NzEyTDYuOTQ3OTkgMTEuMjkyNEw0LjY4NDcyIDkuMDM0NjlMNC41MDc5NCA4Ljg1ODM1TDQuMzMxMzggOS4wMzQ5MUwzLjQ5MDIyIDkuODc2MDdMMy4zMTM0NCAxMC4wNTI5TDMuNDkwMjIgMTAuMjI5Nkw2Ljc3MTM1IDEzLjUxMDhMNi45NDgxMyAxMy42ODc1TDcuMTI0OSAxMy41MTA4TDE0LjI4MzcgNi4zNTE5MloiIGZpbGw9IndoaXRlIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjAuNSIvPgo8L3N2Zz4K);
border-color: #146B90;
top: 0;
background-color: #146B90;
width: 14px;
height: 14px;
background-repeat: no-repeat;
background-size: contain;
background-position: center center;
}

input[type="checkbox"] + div p {
margin: 0;
}
*/

.shaka-spinner-container {
display: none;
}

.video-options p{
margin-bottom: 0;
}

table.rating {
width: 100%;
border-collapse: collapse;
margin-top: 20px;
border-bottom: 0.667px solid var(--new-logo-color-scheme-8-unselected-footer-icon, #666);
box-shadow: 0px 5.333px 10.667px 0px rgba(0, 0, 0, 0.15);
}

.rating th, .rating td {
padding-left: 15px !important;
text-align: left;
border-bottom: 1px solid #ddd;
}

.rating .table>:not(caption)>*>* {
padding: 15px 25px !important;
vertical-align: middle;
}

.rating th {
background: #C0C0C0 !important;
}

.rating label {
display: flex;
}

.sub-question p {
  margin-bottom: 0.2rem;
}

.fullscreen-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.8);
display: flex;
align-items: center;
justify-content: center;
z-index: 99999;
}

.fullscreen-image {
max-width: 100%;
max-height: 100%;
width: 50%;
border: 1px solid #fff;
background: #fff;
padding: 20px;
}

.close-button {
position: absolute;
top: 10px;
right: 10px;
background: none;
color: #fff;
border: none;
font-size: 16px;
cursor: pointer;
padding: 5px 10px;
}

.fullscreen-button {
margin-top: 10px;
padding: 0;
background-color: transparent;
color: #fff;
border: none;
cursor: pointer;
position: absolute;
right: 15px;
bottom: 15px;
}

input[type=range] {
  -webkit-appearance: none; 
  width: 100%;
  height: 8px; 
  background-color: #C6C6C6; 
  border-radius: 5px; 
  outline: none; 
  margin-bottom: 10px; 
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%; 
  height: 5px; 
  border-radius: 5px; 
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none; 
  width: 20px; 
  height: 20px; 
  background: #146B90; 
  border: 1px solid #146B90; 
  border-radius: 50%; 
  cursor: pointer; 
  margin-top: -8px; 
  position: relative;
  z-index: 2;
}

.sliderticks {
  display: flex;
  justify-content: space-between;
  padding: 6px 10px 0;
  margin-bottom: -5px;
}
  
.sliderticks span {
  display: flex;
  justify-content: center;
  width: 1px;
  height: 18px;
  background: #d3d3d3;
  line-height: 0px;
}

.video-container {
  position: relative;
  max-height: 606px;
  height: 606px;
}

.learning_course_banner .video-container {
  height: auto;
  max-height: 500px;
  position: relative;
}

.shaka-player-video > video{
  max-height: 600px;
  height: 600px;
  width: 100%;
  background-color: #000;
}

.custom-overlay {
position: absolute;
top: 0;
left: 0;
right: 0;
top: 0;
width: 100%;
height: 100%;
background: rgba(0, 0, 0, 0.3); 
color: white;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.custom-overlay.quiz {
position: fixed;
z-index: 999999;
margin: 0 auto;
-webkit-backdrop-filter: blur(2px) !important;
backdrop-filter: blur(2px) !important;
}

.video-questions .options p{
margin-bottom: 0;
}

.play-btn {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 99%;
background: rgba(0, 0, 0, 0.5); 
border: none;
cursor: pointer;
}

.correct {
background-color: #D9E8E7;
color: #22A298;
display: flex;
justify-content: flex-start;
width: 100%;
border-radius: 8px;
padding-bottom: 0 !important;
}

.incorrect {
background-color: #F2D7D7;
color: #ED483D;
display: flex;
justify-content: flex-start;
width: 100%;
border-radius: 8px;
padding-bottom: 0 !important;
}

.incorrect span {
padding-right: 0.5rem;
}

.btn-continue{
position: relative;
height: 50px;
width: 300px;
border-radius: 4px;
padding: 0px;
color: #fff;
background-color: #949494;
outline: none;
border: none;

}
.btn-continue-text {position: relative; z-index: 99;}
.btn-continue .loading{
position: absolute;
background-color: #146B90;
left: 0;
top: 0;
right: 0;
bottom: 0;
width: 25%;
height: 50px;
padding: 0px;
border-radius: 4px;
animation: progress 5s 1 linear;   
}
@keyframes progress{
0%{
  width: 25%;
}
25%{
    width: 50%;
}
50%{
    width: 75%;
}
75%{
    width: 85%;
}
100%{
    width: 100%;
}
};

.CircularProgressbar-text {
fill: #060606 !important;
font-size: 25px !important;
dominant-baseline: middle;
text-anchor: middle;
}

.circle-progress {
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: transparent;
  text-align: center;
  line-height: 50px;
  margin: 10px;
}

.circle-progress .title {
  position: relative;
  z-index: 100;
  font-size: 20px;
  color: #f0f0f0;
  line-height: 50px;
  font-weight: 600;
}

.circle-progress .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: transparent;

}

.circle-progress .left,
.circle-progress .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid #f0f0f0;
  border-radius: 100px 0px 0px 100px;
  transform-origin: right;
  border-right: 0;
}

.circle-progress .left {
  animation: unfill 5s linear forwards; 
}

.submit-btn:disabled {
  background: #E1E1E1 !important;
  color: #949494 !important;
  border-color: #E1E1E1 !important;
  opacity: 0.85;
}

@keyframes unfill {
  0% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(0deg); 
  }
}
@media screen and (max-width: 992px)
{
  .language-picker .btn {
    font-size: 14px;
  }
  ul.mcq-options li label {
    display: flex;
    justify-content: start;
    align-items: center;
    line-height: 1.5;
 }
.sidebar-btn {
    top: auto !important;
}
.sidebar-btn.active {
    width: 100% !important;
    margin-left: 0rem !important;
    padding-left: 2px !important;
    position: fixed;
    top: auto !important;
    left: 0;
    z-index: 9999;
    background-color: #fff;
}
  .radio-button-container {
    padding-left: 25px;
    line-height: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 14px;
  }
  .radio-button-container .checkmark:after {
    width: 10px;
    height: 10px;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 18px;
    width: 18px;
}
.checkbox-button-container {
  padding-left: 25px;
  line-height: 20px;
  margin-bottom: 0px;
  margin-left: 0px;
  font-size: 14px;
}
.checkbox-button-container .checkmark {
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
}
.checkbox-button-container .checkmark:after {
  width: 15px;
  height: 15px;
  border-radius: 2px;
}
.video-container {
  height: auto !important;
  max-height: 196px !important;
}
.shaka-player-video {
  max-height: 210px;
  height: auto;
}
.shaka-player-video > video {
  max-height: 210px;
  height: auto;
}
.module-list {
  top: 45px;
}
.questions{
  margin-bottom: 1.8rem !important;
}
.questions > div > div p {
  margin-bottom: 0.2rem !important;
}
}

@media screen and (min-width: 1024px) {
  .learning_course_banner .video-container {
    height: auto;
    max-height: 500px;
    position: relative;
  }
}














